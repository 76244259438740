import styled from 'styled-components';
import { device } from '../../../theme';
import BackgroundImageSrc from '../../../assets/img/subscription-bg.png';

export const DarkerBGLayer = styled.div`
  background-color: rgba(100, 100, 100, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SubscribeSection = styled.section`
  background-image: url(${BackgroundImageSrc});
  background-position: center;
  position: relative;
  padding-top: 16px;
  padding-bottom: 50px;
  ${device.small} {
    padding-top: 76px;
    padding-bottom: 70px;
  }
  ${device.medium} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  ${device.large} {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  ${device.xlarge} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

export const SubscribeImg = styled.figure`
  margin-bottom: 10px;
  display: none;

  ${device.small} {
    margin-bottom: 0;
    display: block;
  }
  img {
    border-radius: 50%;
    border: 15px solid #fff;
    box-shadow: -14.142px -14.142px 40px 0px rgb(255, 255, 255);
  }
  div {
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
  }
`;

export const SubscribeInfo = styled.div`
  ${device.small} {
    padding-left: 35px;
  }
`;
