import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Header from '../containers/layout/header';
import Footer from '../containers/layout/footer';
import { SubscribeWidget } from '../containers/widgets/subscribev2';

const SubscribePage = () => (
  <Layout>
    <SEO path="/subscribe" title="Subscribe to Ozan Tunca" />
    <Header />
    <div className="main-content">
      <SubscribeWidget />
    </div>
    <Footer />
  </Layout>
);

export default SubscribePage;
