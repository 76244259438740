import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../../../components/image';
import Heading from '../../../components/shared/heading';
import Text from '../../../components/shared/text';
import Social from '../../../components/socials/layout-two';
import {
  SubscribeSection,
  SubscribeImg,
  SubscribeInfo,
  DarkerBGLayer,
} from './subscribev2.styles';
import Mailchimp from '../../../components/forms/mailchimp-two';

export const SubscribeText = styled.p`
  color: #fff;
  font-size: 14px;
  margin-bottom: 26px;
  margin-top: -10px;
`;

interface SubscribeWidgetProps {
  nameHeadingCSS?: Record<string, any>;
  nameTextCSS?: Record<string, any>;
  heading2CSS?: Record<string, any>;
  taglineCSS?: Record<string, any>;
  bioCSS?: Record<string, any>;
  socialCSS?: Record<string, any>;
}

export const SubscribeWidget: React.FC<SubscribeWidgetProps> = (props) => {
  const authorData = useStaticQuery(graphql`
    query SubscribeAuthorQuery {
      allAuthorsJson {
        edges {
          node {
            name
            tagline
            bio
            social {
              medium
              instagram
              linkedin
              twitter
              github
              soundcloud
            }
            image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationHeight
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  `);
  const { image, name, bio, social } = authorData.allAuthorsJson.edges[0].node;
  const { nameHeadingCSS, bioCSS, socialCSS, heading2CSS } = props;
  const [isSuccess, setSuccess] = React.useState<boolean>(false);

  return (
    <SubscribeSection>
      <DarkerBGLayer />
      <Container>
        <Row className="align-items-center">
          <Col sm={4}>
            {image && (
              <SubscribeImg>
                <Image fluid={image.childImageSharp.fluid} alt={name} />
              </SubscribeImg>
            )}
          </Col>
          <Col sm={8}>
            <SubscribeInfo>
              {name && <Heading {...nameHeadingCSS}>Hi! I'm Ozan</Heading>}
              {bio && (
                <Text {...bioCSS}>
                  I write tutorials, deep dives and opinion pieces about various
                  web technologies. I strive to share everything I know with you
                  so that we can grow together. If you sign up, you'll receive
                  free courses, ebooks and sometimes notifications about other
                  quality content that will help you become better software
                  developers.
                </Text>
              )}

              <Heading {...heading2CSS}>
                {!isSuccess
                  ? `Get free online courses, ebooks and other quality content`
                  : `Thank you for subscribing!`}
              </Heading>

              {!isSuccess && (
                <div>
                  <Mailchimp setSuccess={setSuccess} backgroundTheme="dark" />
                </div>
              )}

              {social && <Social {...socialCSS} social={social} />}
            </SubscribeInfo>
          </Col>
        </Row>
      </Container>
    </SubscribeSection>
  );
};

SubscribeWidget.defaultProps = {
  nameHeadingCSS: {
    as: 'h1',
    fontSize: ['28px', '32px', '36px'],
    fontWeight: 700,
    color: '#fff',
    lineHeight: 1.1,
  },
  heading2CSS: {
    as: 'h2',
    color: '#fff',
    mt: 0,
    mb: '16px',
    fontSize: ['16px', '20px', '24px'],
  },
  nameTextCSS: {
    as: 'span',
    fontWeight: 300,
  },
  taglineCSS: {
    as: 'h2',
    color: '#fff',
    fontSize: 22,
    mb: ['15px', null, '24px'],
    mt: ['15px', null, '24px'],
  },
  bioCSS: {
    fontSize: '18px',
    color: '#fff',
    fontWeight: 400,
    mb: ['16px', null, '16px'],
    mt: ['23px', null, '33px'],
  },
  socialCSS: {
    color: '#fff',
    borderColor: '#fff',
    mt: '40px',
  },
};
